<template>
  <div class="main">
    <van-search
      v-model="query.code"
      show-action
      label="作业票编号"
      placeholder="输入作业票编号"
    >
      <template #action>
        <div @click="onScan">rfid扫描</div>
      </template>
    </van-search>
    <div class="PTWLIST">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <van-card
          v-for="(items, index) in ptwList"
          :key="index"
          :title="items.code + '(' + items.organization_name + ')'"
        >
          <template #desc>
            <div class="descBox">
              <van-cell
                title="作业许可种类"
                :value="items.ptw_cetificate_type_id"
              />
              <van-cell
                title="作业装置/区域"
                :value="items.ptw_unit_id + '/' + items.area"
              />
              <van-cell title="作业开始时间" :value="items.start_date" />
              <van-cell title="作业结束时间" :value="items.end_date" />
              <van-cell title="当前状态" :value="items.status" />
              <van-cell
                title="相关联许可证编号"
                :label="getOtherPtw(items.LIST_PTW_CETIFICATE_RELATED)"
              />
            </div>
          </template>
          <template #footer>
            <van-button size="small" :to="'/ptwDetail/' + items.id"
              >查看详情</van-button
            >
            <van-button
              size="small"
              :to="'/ptwDetail/' + items.id"
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 2 &&
                  items.actions.length > 0
              "
              >作业票审批</van-button
            >
            <van-button
              size="small"
              :to="
                '/detectionTask/' +
                  items.id +
                  '/' +
                  items.tempptw_cetificate_type_id +
                  '/' +
                  items.temparea
              "
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 4 &&
                  items.actions.length > 0
              "
              >发布任务</van-button
            >
            <van-button
              size="small"
              :to="
                '/delayTask/' +
                  items.id +
                  '/' +
                  items.tempptw_cetificate_type_id
              "
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 12 &&
                  items.actions.length > 0
              "
              >延期审批</van-button
            >
            <van-button
              size="small"
              :to="
                '/siteConfrim/' +
                  items.id +
                  '/' +
                  items.tempptw_cetificate_type_id +
                  '/' +
                  items.gas_inspection_location +
                  '/' +
                  items.tempstatus
              "
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 18 &&
                  items.actions.length > 0
              "
              >延期现场确认</van-button
            >
            <van-button
              size="small"
              :to="
                '/siteConfrim/' +
                  items.id +
                  '/' +
                  items.tempptw_cetificate_type_id +
                  '/' +
                  items.gas_inspection_location +
                  '/' +
                  items.tempstatus
              "
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 5 &&
                  items.actions.length > 0
              "
              >现场确认</van-button
            >
            <van-button
              size="small"
              :to="'/ptwDetail/' + items.id"
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 19 &&
                  items.actions.length > 0
              "
              >延期签发</van-button
            >

            <van-button
              size="small"
              :to="'/ptwDetail/' + items.id"
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 7 &&
                  items.actions.length > 0
              "
              >签发</van-button
            >
            <van-button
              size="small"
              @click="taskSubmit(items.id, '是否确认开始作业?', 0)"
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 16 &&
                  items.actions.length > 0
              "
              >开始作业</van-button
            >
            <van-button
              size="small"
              @click="taskSubmit(items.id, '是否提交完工申请？', 0)"
              type="success"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 8 &&
                  items.actions.length > 0
              "
              >完工申请</van-button
            >
            <van-button
              size="small"
              @click="taskSubmit(items.id, '是否确认整改已完成？', 1)"
              type="primary"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 10 &&
                  items.actions.length > 0
              "
              >整改确认</van-button
            >
            <van-button
              size="small"
              :to="
                '/sitePatrol/' + items.id + '/' + items.gas_inspection_location
              "
              type="primary"
              v-if="
                items.tempstatus == 8 &&
                  (items.tempptw_cetificate_type_id == 7 ||
                    items.tempptw_cetificate_type_id == 8) &&
                  ptw_gas_checker.search(items.tempgas_checker) != -1
              "
              >现场作业</van-button
            >
            <van-button
              size="small"
              @click="cancelSubmit(items.id)"
              type="danger"
              v-if="
                items.tempstatus == 8 && items.templocal_checker == app_user_id
              "
              >中止</van-button
            >
            <van-button
              size="small"
              :to="
                '/delay/' +
                  items.id +
                  '/' +
                  items.start_date +
                  '/' +
                  items.end_date
              "
              type="warning"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 8 &&
                  items.actions.length > 0
              "
              >延期申请</van-button
            >
            <van-button
              size="small"
              type="primary"
              :to="'/acceptance/' + items.id"
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 14 &&
                  items.actions.length > 0
              "
              >发布验收任务</van-button
            >
            <van-button
              size="small"
              type="primary"
              :to="'/acceptanceCheck/' + items.id + '/' + items.end_date"
              v-if="
                items.actions.length > 0 &&
                  (items.tempstatus == 9 || items.tempstatus == 10) &&
                  items.actions.length > 0
              "
              >{{ items.tempstatus == 9 ? "现场验收" : "整改验收" }}</van-button
            >
            <van-button
              size="small"
              type="primary"
              :to="
                '/closePtw/' +
                  items.id +
                  '/' +
                  items.start_date +
                  '/' +
                  items.end_date
              "
              v-if="
                items.actions.length > 0 &&
                  items.tempstatus == 17 &&
                  items.actions.length > 0
              "
              >关票操作</van-button
            >
          </template>
        </van-card>
      </van-pull-refresh>
    </div>
    <!-- rfid 展示 -->
    <van-dialog
      v-model:show="rfidShow"
      title="rfid"
      show-cancel-button
      :close-on-popstate="false"
      :before-close="beforeclose"
    >
      <van-radio-group v-model.trim="query.rfid">
        <van-radio
          v-for="items in rfidList"
          :key="items"
          :name="items"
          icon-size="20px"
          >{{ items }}</van-radio
        >
      </van-radio-group>
    </van-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import {onMounted,reactive,ref, watch} from 'vue';
import { Notify ,Dialog} from 'vant';
import {useRouter} from 'vue-router';
import Http from '../../../api/http';
export default {
    name:'',
    setup() {
        const app_user_id = ref(localStorage.getItem('app_user_id'));
        const ptw_gas_checker = ref(localStorage.getItem('ptw_gas_checker'))
        // 查询
        const query = reactive({
            method:'query',
            queryId:203,
            // method:'listJob',
            t:'PTW_CETIFICATE',
            code: '',
            rfid:'',
            page:1,
            pageSize:2
        });
        const router = useRouter();
         const getOtherPtw = (list) =>{
            var l = [];
            if(list && list.length > 0){
                l = list.map(items=>items.code);
            }
            return l.join(',');
        }
        const ptwList = ref([])
        // rfid 弹出框
        const rfidShow = ref(false);
        const rfidList = ref([]);
        // rfid读取
        const onScan = () =>{
            query.rfid = '';
            // 启动rfid模块服务
            window.rfidPlugin.ReadRfidStart(null,function() {},function() {});
        }
        // 终止rfid 服务
        const stopScan = () =>{
            window.rfidPlugin.StopRfidService(null,function() {},function() {});
        }
        // rfid读取到之后的推送 
        const getRFID = (data)=>{
            if(rfidShow.value == false){
                rfidList.value = [];
                rfidShow.value = true;
                rfidList.value.push(data.strEPC.replace(/\s/g,''));
            }else{
                rfidList.value.push(data.strEPC.replace(/\s/g,''));
            }
            rfidList.value = Http.unique(rfidList.value);
        }
        // 选择rfid之后 提交
        const beforeclose = (action) =>{
            new Promise((resolve) => {
               if (action === 'confirm') {
                    if(query.rfid != ''){
                        stopScan();//终止服务
                        query.code = '';
                        getList();
                        rfidShow.value = false;
                    }else{
                        Notify({ type: 'danger', message: '请选择正确rfid' });
                        resolve(true);
                    }
                } else {
                    // 拦截取消操作
                    rfidList.value = [];
                    stopScan();
                    rfidShow.value = false;
                }
            });
        }
        const getList = () =>{
            Http.get(query,false,true,true)
            .then((res)=>{
                loading.value = false;
                ptwList.value = res;
            })
            .catch(()=>{
                loading.value = false;
            });
        }
        const loading = ref(false);
        const onRefresh = () =>{
            query.page = 1
            loading.value = true;
            getList()
        }
        // 根据作业票编号查询
        watch(()=>query.code,(newval,oldval)=>{
            if(newval!=oldval){
                getList();
            }       
        });
        
        
        // 提交步骤
        const subConfrim = (id,action) =>{
            Http.post({
                method:'saveorupdate',
                t:'PTW_CETIFICATE',
                action:action,
                id:id
            })
            .then(()=>{
                getList();
            })
            .catch(()=>{})
        }
        // 点击开始作业按钮,完工申请,整改确认
        const taskSubmit = (id,msg,type) =>{
            Dialog.confirm({
                title: '标题',
                message: msg,
            })
            .then(() => {
                subConfrim(id,'success');
            })
            .catch(() => {
                if(type == 1){
                    subConfrim(id,'close');
                }
            });
        }

        const cancelSubmit = (id)=>{
            Dialog.confirm({
                title: '标题',
                message: '是否确认中止作业？',
            })
            .then(() => {
                subConfrim(id,'close');
            })
            .catch(() => { });
        }
        onMounted(()=>{
            window.getRFID= getRFID;
            getList()
        });
        return {
            onScan,
            getRFID,
            rfidShow,
            stopScan,
            beforeclose,
            rfidList,
            ptwList,
            query,
            taskSubmit,
            app_user_id,
            cancelSubmit,
            ptw_gas_checker,
            onRefresh,
            loading,
            getOtherPtw
        }
    },
}
</script>
<style lang="less" scoped>
.PTWLIST{
    height: calc(100% - 60px);
}
.van-pull-refresh{
    height:100%;
    overflow-y: auto;
}
</style>